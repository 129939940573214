import React from "react";
import { Link } from "react-router-dom";

function ProfilePageHeader({ title, name, background }) {
  let pageHeader = React.createRef();
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          padding: "8rem 0 1rem 0",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <>
            <h1
              className={"text-center text-white text-700 mt-0"}
              style={{ zIndex: 10 }}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <h6 className={"text-center text-white text-700 mt-0"}>
              <Link to="/" className={"text-center text-white text-700 mt-0"}>
                HOME
              </Link>{" "}
              / <span dangerouslySetInnerHTML={{ __html: name || title }} />
            </h6>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ProfilePageHeader;
