import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import { speakers } from "views/content";

function SpeakerCard({ speaker }) {
  return (
    <Card
      style={{
        maxWidth: "none",
        height: "22rem",
        background: "transparent",
        boxShadow: "none",
      }}
      className="rounded-0"
    >
      <Container fluid className="p-0">
        <img
          alt="..."
          className="img rounded-0 img-responsive"
          width="100%"
          src={require(`assets/images/speakers/${speaker.image}`)}
        />
      </Container>
      <Container style={{ textTransform: "none" }}>
        <Row>
          <Col xs={12} className="mt-2 px-1">
            <h4
              className="d-inline text-white text-700"
              style={{ fontSize: "22px", textTransform: "none" }}
            >
              {speaker.name}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-white text-left px-1">
            <h5
              className="mt-1 text-400 text-white"
              style={{ textTransform: "none", fontSize: "14px", lineHeight: "14px" }}
            >
              {speaker.title}
            </h5>
            <h5
              className="text-700"
              style={{ fontSize: "14px", textTransform: "none", lineHeight: "14px" }}
            >
              {speaker.company && <span>{speaker.company}</span>}
            </h5>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}
const settings = {
  // dots: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 200,
  slidesToShow: 3,
  slidesToScroll: 1,
  rows: 2,
  className: "p-10",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        row: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        row: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Speaker({ showAll, showTitle }) {
  let filteredSpeakers = [];
  if (showAll) {
    filteredSpeakers = speakers;
  } else {
    filteredSpeakers = speakers.filter((p, index) => showAll || index < 8);
  }
  console.log(showAll);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          overflow: "hidden",
        }}
        className="section"
      >
        <Container>
          <Row className="mt-5">
            {showTitle && (
              <Col lg={12}>
                <h2 className="text-700 text-center text-white mt-0 mb-5">PAST SPEAKERS</h2>
              </Col>
            )}
            {filteredSpeakers.map((s, i) => (
              <Col
                lg={3}
                md={6}
                xs={6}
                key={i}
                className="p-1"
                style={{ background: " rgba(255,255,255,0.1)" }}
              >
                <SpeakerCard speaker={s} />
              </Col>
            ))}
            {!showAll && (
              <Col lg={12} className="text-center">
                <Button
                  href="/past-speakers"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                >
                  View All
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
