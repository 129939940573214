import React from "react";
import { NavItem, Nav, Container, Row, Col } from "reactstrap";

import WhoShouldAttend from "./WhoShouldAttend";
import WhyAttend from "./WhyAttend";
import { Link } from "react-router-dom";

function WhoAttend({ match }) {
  const activeTab = match.params.id ? parseInt(match.params.id) : 1;

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section p-5"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs="12">
              <Nav id="tabs" role="tablist" justified card>
                <NavItem
                  className={activeTab === 2 ? "border  rounded-bottom bg-primary" : "border"}
                >
                  <Link
                    className={activeTab === 2 ? "active text-white" : "text-white"}
                    to="/delegates/2"
                  >
                    <h5 className="p-1 my-4 text-uppercase">
                      <b>Who Attends</b>
                    </h5>
                  </Link>
                </NavItem>
                <NavItem
                  className={activeTab === 3 ? "border  rounded-bottom bg-primary" : "border"}
                >
                  <Link
                    className={activeTab === 3 ? "active text-white" : "text-white"}
                    to="/delegates/3"
                  >
                    <h5 className="p-1 my-4 text-uppercase">
                      <b>Why Attend</b>
                    </h5>
                  </Link>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>

      {activeTab === 2 && <WhoShouldAttend />}
      {activeTab === 3 && <WhyAttend />}
    </>
  );
}
export default WhoAttend;
