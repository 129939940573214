import React, { useEffect } from "react";
import anime from "animejs";

function AnimeBackground() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  function animation() {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.9, .1, .2, .3)",
      duration: 1000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        marginTop: "3rem",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: -100,
        marginLeft: "auto",
        // marginRight: "auto",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1280 720"
        style={{
            fill: "none",
          stroke: "#83c55566",
          strokeWidth: 1,
          strokeMiterlimit: 10,
        }}
      >
        <path
          strokeWidth={8}
          stroke="#83c55599"
          class="st0"
          d="M1172.08,383.08c-0.31,7.8,1.54,18.24-1.54,25.52c-3.7,8.68-14.14,9.53-21.93,12.6
	c-9.99,3.95-17.35,10.33-26.63,15.36c-12.23,6.66-25.01,12.32-38.06,17.47c-28.62,11.27-58.55,20.06-86.8,31.55
	c-18.32,7.45-31.78,24.47-39.49,42.25c-3.04,7.08-3.87,13.54-13.14,14.57c-6.94,0.77-4.92-1.02-9.87-4.32
	c-12.77-8.51-23.04-1.51-36.5-1.45c-5.04,0-9.59-0.77-14.74-0.82c-7.68-0.09-14.85-5.09-22.3-2.76c-4.95,1.54-8.19,7.43-13.2,8.45
	c-2.02,0.43-3.56-1.54-5.55-1.59c-4.27-0.11-8.02-0.51-11.55,1.68c-10.81,6.66-0.46,13-1.25,20.63c-0.26,2.28-4.61,8.79-7.08,9.05
	c-4.47,0.48-4.92-2.87-6.6-6.12c-0.31-0.57-0.65-1.17-1.08-1.71c-5.04-6.69-11.18-13.37-16.61-20.23
	c-6.66-8.39-13.94-17.75-21.93-25.15c-12.12-11.24-16.64-22.99-24.3-37.52c-6-11.38-14.99-20.82-27.28-24.44
	c-11.69-3.41-10.36-16.24-20.65-22.9c-4.44-2.9-1.96-8.99-1.99-13.43c-0.06-6.37-3.04-10.64-4.55-16.07
	c-1.65-5.97,1.65-9.76,1.91-15.45c0.28-6.63-4.41-15.56-7.45-21.14c-5.63-10.27-16.22-17.24-24.3-25.8
	c-6.8-7.2-19.17-13.2-23.87-22.13c-4.01-7.68-2.59-16.64-7.45-24.95c-5.32-9.05-11.07-17.44-16.42-26.37
	c-7.4-12.38-12.29-25.58-23.44-35.11c-4.1-3.5-7.71-7.37-11.24-11.29c-1.91-2.16-5.95-9.93-8.59-10.78
	c-2.93-0.97-6.74,4.24-9.76,1.88c-3.16-2.48,2.25-9.22,2.9-12.18c2.08-9.53,1.22-23.27,3.33-34.59c0.48,1.88,0.83,3.7,0.68,5.52
	c5.41,0.4,10.44-1,15.85,0.17c5.52,1.17,9.36,3.39,14.96,1.54c12.46-4.04,16.05-18.49,28.71-21.25c5.66-1.22,9.3-1.54,12.6-6.86
	c1.42-2.28,1.65-4.67,3.5-6.66c2.13-2.28,6.32-3.36,8.71-5.75c6.03-6.06-1.34-5.75-6.2-9.47c-5.06-3.84-8.51-8.28-12.26-13.31
	c-2.33-3.13-6.26-6.17-3.9-9.64c1.65-2.42,5.26-2.28,7.65-2.76c5.18-1.05,9.76-2.36,14.74-4.24c3.02-1.14,25.29-7.25,32.94-13.2
	c10.64,0.6,21.45,2.42,31.32,4.75c12.8,3.07,24.98,12.94,35.65,20.34c14.42,9.99,29.1,20.03,42.33,31.55
	c15.56,13.57,27.91,21.99,49.07,21.99c15.7-0.03,32.8-1.37,49.07-0.77c5.75,0.2,11.41,0.65,16.87,1.51
	c11.98,1.88,1.85,10.04,9.9,14.94c4.01,2.45,20.68-1.14,25.8-1.14c0.54,0.43,1.05,0.85,1.51,1.34c3.61,3.67,4.95,8.96,6.97,13.74
	c1.45,3.39,2.28,6,5.26,8.28c2.42,1.82,4.15,3.07,6.2,5.41c4.64,5.35,11.89,9.96,17.89,13.71c5.86,3.64,11.38,3.24,15.48,9.39
	c5.49,8.31,0.31,17.33,3.19,26.54c1.56,5.04,5.12,12.43,8.68,16.24c2.76,2.93,6.26,4.95,9.08,7.82c0.91,0.97,1.76,2.16,2.62,3.36
	c1.74,2.5,3.53,5.12,6.29,6.17c3.78,1.45,8.22-0.65,11.61,1.76c0.4,0.28,0.77,0.63,1.14,1.05c2.84,3.3,0.48,4.12,5.46,3.58
	c3.64,1.39,2.76,3.44,3.84,6.09c0.17,0.43,0.4,0.88,0.71,1.37c5.58,8.45,13.85,15.53,21.22,22.45c9.93,9.36,19.72,7.65,33.06,8.25
	c8.65,0.4,17.61,0.28,26.34,0.71c7.34,0.37,16.7-2.25,23.61-0.91c0.03,0,0.03,0,0.06,0c11.07,2.13,16.3,13.37,14.77,23.3
	C1174.47,362.99,1172.53,372.5,1172.08,383.08z"
        />
        {/* <path
          class="st0"
          strokeWidth={5}
          d="M691.03,79.84c-7.65,5.95-29.93,12.06-32.94,13.2c-4.98,1.88-9.56,3.19-14.74,4.24c-2.39,0.48-6,0.34-7.65,2.76
	c-2.36,3.47,1.56,6.51,3.9,9.64c3.76,5.04,7.2,9.47,12.26,13.31c4.86,3.73,12.23,3.41,6.2,9.47c-2.39,2.39-6.57,3.47-8.71,5.75
	c-1.85,1.99-2.08,4.38-3.5,6.66c-3.3,5.32-6.94,5.63-12.6,6.86c-12.66,2.76-16.24,17.21-28.71,21.25c-5.6,1.85-9.45-0.37-14.96-1.54
	c-5.41-1.17-10.44,0.23-15.85-0.17c0.14-1.82-0.2-3.64-0.68-5.52c-0.88-3.44-2.28-7.03-2.13-10.75c-6.29-11.01,1.11-31.09,3.7-52.49
	c1.42-11.78,7.34-25.35,12.01-36.41c3.36-7.99,8.56-13.2,16.41-17.01c9.39-4.52,20.91-4.15,31.07-5.78
	c11.41-1.82,19.2-7,29.44-12.01c5.06-2.48,12.09-5.04,15.79,0.06c1.91,2.65,1.88,7.17,2.82,10.24c1.88,6.29,5.63,13.12,5.32,19.86
	c-0.14,3.1-2.48,6-1.08,9.25c1.59,3.67,6.17,1.91,7.11,4.75C693.96,76.85,692.97,78.33,691.03,79.84z"
        />
        <path
          class="st0"
          strokeWidth={5}
          d="M1180.13,252.84c-2.02,3.41-3.78,5.06-7.45,4.21c-3.53-0.8-5.58-4.47-5.95,2.42
	c-0.17,3.04-0.2,13.29,1.31,15.68c1.88,3.04,7.97,0.4,6.49,6.03c-7.14,1.25-10.55,5.72-11.29,12.35
	c-1.22,11.61-3.27,21.99-2.19,33.91c0.06,0.68,0.11,1.34,0.17,1.93c-6.91-1.34-16.27,1.28-23.61,0.91
	c-8.73-0.43-17.7-0.31-26.34-0.71c-13.34-0.6-23.13,1.11-33.06-8.25c-7.37-6.91-15.65-14-21.22-22.45
	c-0.31-0.48-0.54-0.94-0.71-1.37c3.95,0.85,6.51,6.2,11.44,5.58c4.61-0.6,7.62-4.86,11.69-6.88c5.41-2.7,12.03-2.99,17.95-3.56
	c5.38-0.51,11.07-0.83,16.3-2.33c6.14-1.76,9.87-6.57,15.48-9.5c4.44-2.3,5.86-2.84,7.06-7.74c0.82-3.33,0.6-6.06,2.48-8.82
	c3.64-5.38,9.56-9.42,13.37-15.05c2.59-3.81,4.24-8.22,6.86-12.01c1.88-2.73,7.99-15.53,12.15-13.88c2.02,0.8,1.62,5.04,2.79,6.37
	c1.56,1.79,3.36,1.39,4.75,3.76C1181.35,238.16,1182.95,248.09,1180.13,252.84z"
        />
        <path
          class="st0"
          strokeWidth={5}
          d="M1121.44,526.83c0,0-5.35,0.74-19.2,14.57c-4.95,4.98-6,12.15-9.73,17.58c-6.17,8.96-18.58,14.91-28.14,19.49
	c-12.69,6.06-28.9,12.38-39.77,21.37c-6.15,5.12-7.71,11.95-13.34,17.13c-12.52,11.44-27.82,7.37-39.2,16.5
	c-13.77,11.07-28.53,16.27-45.26,19.32c-10.27,1.88-10.53,4.27-17.75,10.55c-9.27,8.11-22.45,12.26-33.94,15.85
	c-12.35,3.84-36.64,13.68-39.69-4.21c-1.02-6.09,1.62-11.52-0.26-17.64c-1.62-5.41-6.17-9.79-8.08-15.59
	c-2.7-8.25-2.13-16.96-5.75-25.04c-2.79-6.23-4.01-8.56-4.58-15.56c-1-12.32-0.97-23.9-0.91-36.02c1.68,3.24,2.13,6.6,6.6,6.12
	c2.48-0.26,6.83-6.77,7.08-9.05c0.8-7.62-9.56-13.97,1.25-20.63c3.53-2.19,7.28-1.79,11.55-1.68c1.99,0.06,3.53,2.02,5.55,1.59
	c5.01-1.02,8.25-6.91,13.2-8.45c7.45-2.33,14.62,2.67,22.3,2.76c5.15,0.06,9.7,0.82,14.74,0.82c13.46-0.06,23.73-7.06,36.5,1.45
	c4.95,3.3,2.93,5.09,9.87,4.32c9.27-1.02,10.1-7.48,13.14-14.57c7.71-17.78,21.17-34.79,39.49-42.25
	c28.25-11.49,58.18-20.28,86.8-31.55c0.85,2.9,1.85,6.09,3.39,9.5c5.97,13.14,10.7,17.58,16.07,30.84
	C1107.78,505.21,1119.19,520.69,1121.44,526.83z"
        />
        <path
          class="st0"
          strokeWidth={5}
          d="M1049.95,279.01c-0.82,2.45-2.16,5.69-4.13,7.77c-3.39-2.42-7.82-0.31-11.61-1.76
	c-2.76-1.05-4.55-3.67-6.29-6.17c0.2-1.79,0.88-3.56,0.77-5.41c-0.34-5.55-3.41-8.99-1.39-14.65c1.68-4.69,3.24-27.88,13.17-19.89
	c1.62,1.28,2.08,3.41,3.36,5.04c2.02,2.53,3.87,2.87,3.76,6.54c-0.09,2.25-1.02,4.49-0.48,6.77c0.57,2.22,2.39,3.76,3.39,5.78
	C1053.22,268.43,1051.82,273.55,1049.95,279.01z"
        />
        <path
          class="st0"
          strokeWidth={5}
          d="M952.57,174.35c-0.46-0.48-0.97-0.91-1.51-1.34c-5.12,0-21.79,3.58-25.8,1.14c-8.05-4.89,2.08-13.06-9.9-14.94
	c-5.46-0.85-11.12-1.31-16.87-1.51c2.08-3.73,5.09-7.31,6.83-10.95c2.93-6.2,3.5-14.34,8.65-19.32c3.9-3.81,7.48-1.31,12.29-1.17
	c3.36,0.11,6.23-1.42,8.65,1.17c1.48,1.59,1.37,3.84,3.39,5.35c1.45,1.11,4.41,0.48,4.41,3.07c0,2.93-3.16,2.11-4.81,2.5
	c-2.48,0.57-10.95,4.64-8.73,8.59c1.14,2.02,4.61,0.37,6.51,0.97c1.88,0.63,3.1,1.76,4.35,3.19c3.39,3.81,6.63,9.1,9.62,13.43
	C951.57,167.35,952.31,170.76,952.57,174.35z"
        /> */}
        <path
          class="st0"
          d="M580.47,198.47v0.01l-5.09,12.12c-2.72,0.38-5.92,4-8.53,1.96c-3.16-2.48,2.25-9.22,2.9-12.18
	c1.21-5.56,1.43-12.56,1.81-19.67l1.24-0.51L580.47,198.47z"
        />
        <path
          class="st0"
          d="M571.74,177.68l1.06,2.52l-1.24,0.51c0.02-0.33,0.04-0.67,0.05-1v-0.01
	C571.65,179.03,571.69,178.35,571.74,177.68z"
        />
        <path
          class="st0"
          d="M609.21,216.56l-8.24,20.62c-1.39-1.55-2.89-3.03-4.53-4.43c-4.1-3.5-7.71-7.37-11.24-11.29
	c-1.22-1.38-3.3-5.03-5.33-7.72l11.25-4.64L609.21,216.56z"
        />
        <path
          class="st0"
          d="M706.5,324.47l-7.66,18.22l-44.67-18.1l-4.59,1.84c-2-1.87-3.77-3.85-5.1-5.99l9.71,3.92l44.61-18.21
	L706.5,324.47z"
        />
        <path
          class="st0"
          d="M735.31,432.28l-18.16,7.64l-10.07-4.23l-0.01-0.01c-0.9-0.9-1.9-1.73-3.07-2.48
	c-2.61-1.71-2.83-4.51-2.57-7.43v-0.01l15.66-38.27l-18.25-44.8l18.31-7.54l18.12,7.46v0.01l-17.95,44.89L735.31,432.28z"
        />
        <path
          class="st0"
          d="M727.81,450.48l3.41,8.25c-2.07-1.07-4.26-1.95-6.57-2.63c-10.37-3.02-10.49-13.45-17.57-20.41l10.07,4.23
	l18.16-7.64l-7.5,18.19V450.48z"
        />
        <path
          class="st0"
          d="M832.53,450.46L832.53,450.46l-7.63,18.17l-44.68-18.09l-38.84,15.57c-3.02-2.95-6.41-5.46-10.16-7.38
	l-3.41-8.25v-0.01l7.5-18.19l44.93,18.13l44.61-18.21l0.01,0.01L832.53,450.46z"
        />
        <path
          class="st0"
          d="M828.03,565.7c-1.56,2.57-3.97,5.4-5.58,5.57c-4.47,0.48-4.92-2.87-6.6-6.12c-0.31-0.57-0.65-1.17-1.08-1.71
	c-0.22-0.3-0.45-0.59-0.69-0.89l10.78-4.4L828.03,565.7z"
        />
        <path
          class="st0"
          d="M829.53,562.22c-0.09,0.79-0.67,2.1-1.5,3.48l-3.17-7.55l4.62,1.94C829.58,560.79,829.6,561.5,829.53,562.22z"
        />
        <path
          class="st0"
          d="M829.48,560.09l-4.62-1.94l2.1-5.13C827.9,555.29,829.14,557.62,829.48,560.09z"
        />
        <path
          class="st0"
          d="M861.26,468.56l-17.94,44.9l10.04,24.98c-1.69,1.42-3.44,2.64-5.48,3.06c-2.02,0.43-3.56-1.54-5.55-1.59
	c-3.83-0.1-7.24-0.43-10.45,1.09l11.27-27.56l-18.25-44.81l18.28-7.53l18.08,7.45V468.56z"
        />
        <polygon
          class="st0"
          points="780.22,450.54 824.9,468.63 843.15,513.44 811.59,526.6 780.27,513.55 780.11,513.48 780.01,513.45 
	780.15,513.39 780.18,513.33 780.18,513.32 780.17,513.31 780.18,513.31 780.18,513.29 767.9,482 "
        />
        <path
          class="st0"
          d="M987.32,468.56l-13.51,33.82l-0.01,0.01c-2.17,2.39-4.2,4.92-6.09,7.55l-16.81-41.31l18.3-7.54l18.11,7.46
	L987.32,468.56z"
        />
        <path
          class="st0"
          d="M1084.58,450.46L1084.58,450.46l-1.65,3.95c-7.08,2.78-14.25,5.4-21.44,7.96l-29.21-11.83l-44.95,18.02h-0.01
	l-0.01-0.01l-7.45-18.07v-0.01l7.5-18.19l44.94,18.13l44.61-18.21L1084.58,450.46z"
        />
        <path
          class="st0"
          d="M1113.36,432.28l-4.52,10.98h-0.01c-8.14,3.89-16.46,7.44-24.91,10.77c-0.33,0.13-0.66,0.26-0.99,0.38
	l1.65-3.94v-0.01l-7.67-18.26l18.32,7.71L1113.36,432.28z"
        />
        <path
          class="st0"
          d="M1122.81,436.09c-0.27,0.16-0.55,0.32-0.83,0.47c-4.32,2.35-8.7,4.57-13.14,6.7l4.52-10.98L1122.81,436.09z"
        />
        <path
          class="st0"
          d="M1113.32,342.61l-18.09-7.45l-18.28,7.53l7.15-17c7.18,3.96,14.67,3.58,23.81,3.8L1113.32,342.61z"
        />
        <path
          class="st0"
          d="M1084.1,325.69l-7.15,17l-44.67-18.1l-44.96,18.02l-7.48-18.14l7.52-18.24l44.94,18.13l34.3-14
	c3.79,3.82,7.82,7.42,11.62,10.99C1080.18,323.2,1082.13,324.61,1084.1,325.69z"
        />
        <polygon class="st0" points="1032.3,324.36 987.36,306.23 1019.07,292.9 " />
        <path
          class="st0"
          d="M989.3,215.77l-1.97,0.79h-0.01l-0.01-0.01l-1.12-2.72c0.92,0.58,1.83,1.15,2.71,1.7
	C989.03,215.61,989.17,215.69,989.3,215.77z"
        />
        <path
          class="st0"
          d="M987.31,216.55l-18.11-7.46l-18.3,7.54l7.63-18.15v-0.01l-7.67-18.26l6.69,2.81c0.67,1.7,1.29,3.44,2,5.11
	c1.45,3.39,2.28,6,5.26,8.28c2.42,1.82,4.15,3.07,6.2,5.41c3.96,4.57,9.82,8.59,15.18,12.01L987.31,216.55z"
        />
        <path
          class="st0"
          d="M957.55,183.02l-6.69-2.81l-0.01-0.01l2.18-5.33C955.11,177.18,956.4,180.06,957.55,183.02z"
        />
        <path
          class="st0"
          d="M919.33,166.95l-3.14-7.55c6.69,1.32,5.48,4.91,5.58,8.58L919.33,166.95z"
        />
        <path
          class="st0"
          d="M861.31,180.28l-18.13,7.63l-18.32-7.7v-0.01l9.56-23.37c4.54,1.1,9.48,1.68,15.01,1.68
	c1.03,0,2.07-0.01,3.12-0.02L861.31,180.28z"
        />
        <polygon class="st0" points="780.18,135.36 780.18,135.37 780.17,135.36 " />
        <path
          class="st0"
          d="M780.18,135.36h-0.01l0.01,0.01l-0.04,0.08l-0.12,0.05l-0.02-0.01l-0.03,0.01l-31.27-12.28l-13.43-32.6
	l-17.95,44.89l17.99,44.77l-18.16,7.64l-18.35-7.72l18.29-44.71l-18.25-44.8l17.8-7.33c0.7,0.14,1.39,0.29,2.09,0.44
	c1.23,0.27,2.44,0.54,3.65,0.83c4.23,1.01,8.4,2.77,12.46,4.94c0.43,0.23,0.85,0.46,1.28,0.7c1.31,0.73,2.61,1.5,3.9,2.3l0.03,0.01
	c6.33,3.91,12.36,8.49,17.98,12.39c4.68,3.24,9.39,6.49,14.07,9.8l8.08,20.58V135.36z"
        />
        <path
          class="st0"
          d="M716.64,83.36l-17.8,7.33l4.07-9.68C707.57,81.63,712.17,82.43,716.64,83.36z"
        />
        <path
          class="st0"
          d="M702.91,81.01l-4.07,9.68l-15.76-6.39c3.23-1.42,6.06-2.93,7.98-4.42C695.01,80.1,698.98,80.49,702.91,81.01z"
        />
        <path
          class="st0"
          d="M1158.32,387.6l-0.16-0.07l-13.09,31.42l-31.71,13.33l-18.13,7.63l-18.32-7.71l-44.61,18.21l-44.94-18.13
	l-7.5,18.19v0.01l7.45,18.07l-18.11-7.46l-18.3,7.54l-13.31,31.66l-31.4,13.08l0.08,0.18l-0.16-0.07l-0.1-0.03h-0.02l-31.29,12.27
	l-31.38-12.26l17.94-44.9v-0.01l-18.08-7.45l-18.28,7.53l7.63-18.16v-0.01l-7.67-18.25l-0.01-0.01l-44.61,18.21l-44.93-18.13
	l-17.99-44.77l17.95-44.89v-0.01l-18.12-7.46l-18.31,7.54l7.66-18.22l-7.7-18.32l-44.61,18.21l-13.23-31.46l13.1-31.42l-0.1-0.03
	h-0.02l-31.3-12.28l-13.43-32.61l-18.09-7.46l-11.25,4.64c-1.16-1.53-2.3-2.76-3.26-3.06c-0.4-0.13-0.81-0.15-1.23-0.08l5.09-12.12
	v-0.01l-7.67-18.27l3.61-8.83c4.43-0.05,8.68-0.87,13.2,0.11c5.52,1.17,9.36,3.39,14.96,1.54c0.53-0.17,1.03-0.36,1.53-0.57
	c3.63-1.5,6.47-3.9,9.08-6.57l-5.92,14.4l31.7-13.33l13.23,31.46l13.09-31.46l-13.07-31.35l-0.09-0.22l31.47-13.02l13.25-31.67
	l18.25,44.8L698.8,180.2l18.35,7.72l18.16-7.64l-17.99-44.77l17.95-44.89l13.43,32.6l31.27,12.28l0.03-0.01l0.02,0.01l0.12-0.05
	l0.04-0.08l0.02,0.06l0.07,0.17l31.32,13.05l13.27,31.55v0.01l18.32,7.7l18.13-7.63l31.71-13.33l13.22,31.46l44.61-18.21l0.01,0.01
	l7.67,18.26v0.01l-7.63,18.15l18.3-7.54l18.11,7.46l0.01,0.01l13.43,32.61l-31.37,12.29l31.37,12.26l-13.39,32.5v0.01l-7.52,18.24
	l7.48,18.14l44.96-18.02l44.67,18.1l18.28-7.53l18.09,7.45l32.63,13.45l12.28,31.29v0.02l0.02,0.06L1158.32,387.6z"
        />
        <line class="st0" x1="580.5" y1="198.53" x2="580.47" y2="198.48" />
        <polyline
          class="st0"
          points="571.61,179.7 571.61,179.71 572.8,180.2 591.12,187.91 591.15,187.92 "
        />
        <path
          class="st0"
          d="M576.41,171.37l-3.61,8.83l-1.06-2.52c0.26-4.07,0.63-8.11,1.34-11.89c0.48,1.88,0.83,3.7,0.68,5.52
	C574.65,171.38,575.54,171.39,576.41,171.37z"
        />
        <path
          class="st0"
          d="M572.8,180.2l-1.24,0.51c0.02-0.33,0.04-0.67,0.05-1v-0.01c0.04-0.67,0.08-1.35,0.13-2.02L572.8,180.2z"
        />
        <path class="st0" d="M572.8,180.2l-1.19-0.5c0.04-0.67,0.08-1.35,0.13-2.02L572.8,180.2z" />
        <line class="st0" x1="580.5" y1="198.41" x2="580.47" y2="198.47" />
        <line class="st0" x1="591.15" y1="209.09" x2="591.12" y2="209.1" />
        <polyline
          class="st0"
          points="601.74,198.53 601.76,198.47 601.76,198.46 609.25,180.28 609.26,180.28 "
        />
        <polyline
          class="st0"
          points="654.12,135.38 685.54,148.65 698.8,180.2 706.47,198.47 706.47,198.48 706.5,198.53 "
        />
        <line class="st0" x1="698.8" y1="180.2" x2="667.28" y2="166.95" />
        <line class="st0" x1="717.15" y1="83.15" x2="716.64" y2="83.36" />
        <polyline class="st0" points="609.26,180.28 609.25,180.28 591.12,187.91 591.09,187.92 " />
        <path
          class="st0"
          d="M698.84,90.69l-32.44,13.37l-4.71-12.13c5.1-1.59,14.2-4.43,21.39-7.63L698.84,90.69z"
        />
        <path
          class="st0"
          d="M644.58,97.06l-2.74,7l-6.7-2.76c0.12-0.4,0.31-0.81,0.59-1.22c1.65-2.42,5.26-2.28,7.65-2.76
	C643.78,97.24,644.19,97.15,644.58,97.06z"
        />
        <path
          class="st0"
          d="M615.18,165.88l-5.92,14.4h-0.01l-3.15-7.83C609.73,170.95,612.57,168.55,615.18,165.88z"
        />
        <polygon class="st0" points="654.19,198.41 609.26,180.28 640.96,166.95 " />
        <polygon
          class="st0"
          points="717.09,135.49 698.8,180.2 654.19,198.41 667.28,166.95 654.21,135.6 654.12,135.38 685.59,122.36 
	698.84,90.69 "
        />
        <path
          class="st0"
          d="M666.4,104.06l-8.67,22.08c-1.71-0.83-3.96-1.67-5.84-3.11c-1.42-1.08-2.72-2.21-3.93-3.39l-6.12-15.58l2.74-7
	c4.7-1,8.95-2.25,13.54-3.98c0.57-0.22,1.84-0.61,3.57-1.15L666.4,104.06z"
        />
        <path
          class="st0"
          d="M647.96,119.64c-3.09-2.99-5.63-6.3-8.33-9.92c-2.05-2.76-5.35-5.45-4.49-8.42l6.7,2.76L647.96,119.64z"
        />
        <path
          class="st0"
          d="M698.84,90.69l-13.25,31.67l-31.47,13.02l0,0l-0.03,0.07l-0.03,0.08l-13.1,31.42l-31.7,13.33l5.92-14.4
	l0.02-0.03c5.48-5.59,9.87-12.29,18.08-14.08c5.66-1.22,9.3-1.54,12.6-6.86c1.42-2.28,1.65-4.67,3.5-6.66
	c0.77-0.82,1.78-1.48,2.9-2.1l0.03-0.02c0.44-0.25,0.9-0.49,1.36-0.73l0.39-0.21c0.05-0.02,0.1-0.05,0.14-0.07h0.01
	c1.43-0.77,2.83-1.56,3.88-2.62c3.7-3.72,2.35-5.04-0.36-6.36l8.67-22.08L698.84,90.69z"
        />
        <polygon class="st0" points="615.18,165.88 609.26,180.28 609.25,180.28 " />
        <polygon
          class="st0"
          points="667.28,166.95 654.19,198.41 640.96,166.95 654.06,135.53 654.09,135.45 654.12,135.38 654.14,135.43 
	654.14,135.43 "
        />
        <polyline
          class="st0"
          points="654.12,135.38 685.59,122.36 717.09,135.49 685.54,148.65 698.8,180.2 667.26,166.9 654.12,135.38 "
        />
        <polyline
          class="st0"
          points="601.74,198.41 601.76,198.46 601.76,198.47 609.21,216.56 609.22,216.56 622.64,249.17 "
        />
        <polyline
          class="st0"
          points="653.96,261.45 654.1,261.39 654.14,261.37 685.54,248.29 698.84,216.63 706.47,198.48 706.47,198.47 
	706.5,198.41 "
        />
        <polyline class="st0" points="698.8,306.15 685.54,274.6 654.21,261.55 654.06,261.48 " />
        <line class="st0" x1="653.95" y1="261.45" x2="653.95" y2="261.44" />
        <polyline
          class="st0"
          points="717.15,209.09 666.4,230 654.11,261.31 667.28,292.9 717.15,313.86 "
        />
        <line class="st0" x1="591.12" y1="209.1" x2="591.09" y2="209.09" />
        <polyline
          class="st0"
          points="654.06,261.48 654.1,261.39 654.12,261.33 654.13,261.31 654.12,261.28 641.84,230 609.22,216.56 
	609.21,216.56 "
        />
        <path
          class="st0"
          d="M622.64,249.17l-10.74,4.21c-3.15-5.76-6.5-11.31-10.93-16.2l8.24-20.62L622.64,249.17z"
        />
        <path
          class="st0"
          d="M654.19,324.36l-9.71-3.92c-0.26-0.41-0.51-0.83-0.73-1.26c-3.93-7.52-2.65-16.27-7.16-24.44l4.37-1.84
	L654.19,324.36z"
        />
        <polygon
          class="st0"
          points="717.09,261.44 698.8,306.15 654.19,324.36 640.96,292.9 654.06,261.48 653.96,261.45 653.94,261.45 
	622.64,249.17 609.21,216.56 609.22,216.56 654.17,198.54 698.84,216.63 "
        />
        <path
          class="st0"
          d="M653.94,261.45l-30.63,12.01c-1.15-1.85-2.3-3.71-3.43-5.6c-2.87-4.79-5.35-9.71-7.98-14.48l10.74-4.21
	L653.94,261.45z"
        />
        <path
          class="st0"
          d="M654.06,261.48l-13.1,31.42l-4.37,1.84c-0.09-0.17-0.19-0.34-0.29-0.51c-4.2-7.14-8.66-13.87-12.99-20.77
	l30.63-12.01h0.02L654.06,261.48z"
        />
        <polygon
          class="st0"
          points="685.54,274.6 698.8,306.15 667.28,292.9 654.19,324.36 640.96,292.9 654.06,261.48 653.96,261.45 
	653.94,261.45 622.64,249.17 609.21,216.56 609.22,216.56 641.84,230 654.17,198.54 666.4,230 698.84,216.63 685.54,248.29 
	717.09,261.44 "
        />
        <polyline class="st0" points="779.97,135.5 780,135.51 780.02,135.5 " />
        <line class="st0" x1="780.2" y1="135.43" x2="780.14" y2="135.45" />
        <polyline class="st0" points="824.86,180.2 824.85,180.2 811.59,148.65 " />
        <polyline
          class="st0"
          points="727.79,198.53 727.81,198.47 727.81,198.46 735.31,180.28 748.7,147.78 779.97,135.5 "
        />
        <polyline class="st0" points="780.02,135.5 780.11,135.53 780.27,135.6 " />
        <polyline class="st0" points="824.86,180.21 832.53,198.47 832.56,198.53 " />
        <polyline class="st0" points="824.86,180.21 824.85,180.2 793.33,166.95 780.27,135.6 " />
        <line class="st0" x1="843.2" y1="187.92" x2="843.18" y2="187.91" />
        <line class="st0" x1="718.73" y1="83.8" x2="717.15" y2="83.15" />
        <polyline class="st0" points="735.31,180.28 767.02,166.95 780.11,135.53 780.14,135.45 " />
        <path
          class="st0"
          d="M834.42,156.83l-9.56,23.37l-13.27-31.55l2.66-1.11C820.56,151.86,826.97,155.05,834.42,156.83z"
        />
        <polygon class="st0" points="780.18,135.36 780.18,135.37 780.17,135.36 " />
        <polygon
          class="st0"
          points="824.86,180.2 824.85,180.2 780.24,198.41 735.31,180.28 717.32,135.51 735.27,90.62 748.7,123.22 
	779.97,135.5 780,135.49 780.02,135.5 780.14,135.45 780.18,135.37 780.2,135.43 780.27,135.6 811.59,148.65 "
        />
        <path
          class="st0"
          d="M814.25,147.54l-2.66,1.11l-31.32-13.05l-0.07-0.17l12.57-5.24c2.58,2.06,5.11,4.17,7.59,6.33
	C805.18,140.72,809.69,144.42,814.25,147.54z"
        />
        <path
          class="st0"
          d="M792.77,130.19l-12.57,5.24l-0.02-0.06v-0.02l4.49-11.45C787.41,125.96,790.11,128.05,792.77,130.19z"
        />
        <path
          class="st0"
          d="M784.67,123.9l-4.49,11.45l-8.08-20.58C776.33,117.75,780.54,120.79,784.67,123.9z"
        />
        <polygon class="st0" points="780.18,135.36 780.18,135.37 780.17,135.36 " />
        <polygon
          class="st0"
          points="824.86,180.2 824.85,180.2 793.33,166.95 780.24,198.41 767.02,166.95 735.31,180.28 748.7,147.78 
	717.32,135.51 748.7,123.22 779.97,135.5 780,135.49 780.02,135.5 780.14,135.45 780.18,135.37 780.2,135.43 780.27,135.6 
	811.59,148.65 "
        />
        <polyline
          class="st0"
          points="832.56,198.41 811.59,248.29 780,261.45 748.7,249.17 727.79,198.41 "
        />
        <polyline
          class="st0"
          points="832.56,324.47 811.59,274.6 780,261.44 748.7,273.72 727.79,324.47 "
        />
        <polyline
          class="st0"
          points="843.2,209.09 792.45,230 780.17,261.31 793.33,292.9 843.2,313.86 "
        />
        <polyline
          class="st0"
          points="717.15,313.86 767.02,292.9 780.18,261.31 767.9,230 717.15,209.09 "
        />
        <polygon
          class="st0"
          points="843.15,261.44 824.9,216.63 780.22,198.54 735.27,216.56 717.32,261.46 735.31,306.23 780.24,324.36 
	824.86,306.15 "
        />
        <polygon
          class="st0"
          points="780.22,198.54 767.9,230 735.27,216.56 748.7,249.17 717.32,261.46 748.7,273.72 735.31,306.23 
	767.02,292.9 780.24,324.36 793.33,292.9 824.86,306.15 811.59,274.6 843.15,261.44 811.59,248.29 824.9,216.63 792.45,230 "
        />
        <polyline class="st0" points="853.79,198.53 853.81,198.47 853.81,198.46 861.31,180.28 " />
        <line class="st0" x1="958.56" y1="198.53" x2="958.53" y2="198.48" />
        <line class="st0" x1="843.18" y1="187.91" x2="843.15" y2="187.92" />
        <path
          class="st0"
          d="M953.03,174.87l-2.18,5.33l-2.91-6.92c1.26-0.14,2.33-0.23,3.13-0.23c0.54,0.43,1.05,0.85,1.51,1.34
	C952.73,174.55,952.88,174.71,953.03,174.87z"
        />
        <path
          class="st0"
          d="M950.85,180.2l-44.61,18.21l13.09-31.46l2.44,1.03c0.05,2.2,0.56,4.42,3.5,6.21c1.97,1.2,6.99,0.95,12.2,0.39
	h0.01L950.85,180.2z"
        />
        <path
          class="st0"
          d="M870.49,158l-9.18,22.28l-8.76-21.79C858.39,158.42,864.41,158.2,870.49,158z"
        />
        <polygon class="st0" points="906.24,198.41 861.31,180.28 893.02,166.95 " />
        <path
          class="st0"
          d="M921.77,167.98l-2.44-1.03l-3.14-7.55C922.88,160.72,921.67,164.31,921.77,167.98z"
        />
        <path
          class="st0"
          d="M919.33,166.95l-13.09,31.46l-13.22-31.46l3.86-9.26c0.54,0.01,1.08,0.03,1.62,0.05
	c5.75,0.2,11.41,0.65,16.87,1.51c0.28,0.04,0.55,0.09,0.81,0.15h0.01L919.33,166.95z"
        />
        <path
          class="st0"
          d="M896.88,157.69l-3.86,9.26l-31.71,13.33l9.18-22.28C879.28,157.7,888.18,157.43,896.88,157.69z"
        />
        <path
          class="st0"
          d="M921.77,167.98l-2.44-1.03l-3.14-7.55C922.88,160.72,921.67,164.31,921.77,167.98z"
        />
        <path
          class="st0"
          d="M950.85,180.2l-13.37-5.62c3.75-0.42,7.59-0.99,10.46-1.3L950.85,180.2z"
        />
        <polyline
          class="st0"
          points="853.79,198.41 853.81,198.46 853.81,198.47 861.26,216.55 861.26,216.56 861.27,216.56 874.7,249.17 
	905.99,261.45 906,261.45 906.01,261.45 906.15,261.39 906.19,261.37 937.59,248.29 950.9,216.63 "
        />
        <line class="st0" x1="958.56" y1="198.41" x2="958.53" y2="198.47" />
        <polyline
          class="st0"
          points="958.56,324.47 937.59,274.6 906,261.44 874.7,273.72 853.79,324.47 "
        />
        <polyline
          class="st0"
          points="969.2,313.86 950.86,306.15 950.85,306.15 919.33,292.9 906.27,261.55 906.19,261.37 906.18,261.32 
	906.17,261.31 906.18,261.3 918.45,230 950.9,216.63 "
        />
        <polyline
          class="st0"
          points="843.15,313.86 893.02,292.9 906.18,261.31 893.9,230 843.15,209.09 "
        />
        <polygon
          class="st0"
          points="969.14,261.44 950.9,216.63 906.22,198.54 861.26,216.56 843.32,261.46 861.31,306.23 906.24,324.36 
	950.85,306.15 "
        />
        <polygon
          class="st0"
          points="906.22,198.54 893.9,230 861.26,216.56 874.7,249.17 843.32,261.46 874.7,273.72 861.31,306.23 
	893.02,292.9 906.24,324.36 919.33,292.9 950.85,306.15 937.59,274.6 969.14,261.44 937.59,248.29 950.9,216.63 918.45,230 "
        />
        <path
          class="st0"
          d="M654.17,324.59l-1.71,4.36c-1-0.83-1.96-1.66-2.88-2.52L654.17,324.59z"
        />
        <path
          class="st0"
          d="M698.84,342.69l-20.7,8.53c-3.59-3.26-7.26-6.46-10.52-9.91c-2.79-2.95-6.52-5.71-10.27-8.55l-0.01-0.01
	l-3.17-8.16L698.84,342.69z"
        />
        <path
          class="st0"
          d="M717.09,387.49l-19.4-8.09c-1.58-4.49-3.97-8.98-5.77-12.29c-0.61-1.11-1.27-2.17-1.99-3.21l8.91-21.21
	L717.09,387.49z"
        />
        <path
          class="st0"
          d="M701.43,425.76c0.18-2.05,0.59-4.16,0.58-5.99c-0.06-6.37-3.04-10.64-4.55-16.07
	c-0.86-3.12-0.37-5.65,0.33-8.16l19.3-8.05L701.43,425.76z"
        />
        <path class="st0" d="M657.34,332.75c-1.65-1.24-3.3-2.5-4.88-3.8l1.71-4.36L657.34,332.75z" />
        <path
          class="st0"
          d="M698.84,342.69l-8.91,21.21c-3.22-4.7-7.45-8.74-11.79-12.68L698.84,342.69z"
        />
        <path
          class="st0"
          d="M699.37,388.25c0.11-2.7-0.59-5.77-1.68-8.85l19.4,8.09l-19.3,8.05C698.43,393.25,699.24,390.97,699.37,388.25z
	"
        />
        <line class="st0" x1="735.27" y1="342.61" x2="727.79" y2="324.47" />
        <polyline
          class="st0"
          points="735.27,342.62 748.7,375.22 779.97,387.5 780,387.51 780.02,387.5 780.14,387.45 780.2,387.43 
	811.59,374.34 824.9,342.69 832.56,324.47 "
        />
        <line class="st0" x1="727.81" y1="450.48" x2="727.79" y2="450.53" />
        <line class="st0" x1="832.56" y1="450.53" x2="832.53" y2="450.47" />
        <polyline
          class="st0"
          points="824.86,432.21 824.86,432.2 824.85,432.2 811.59,400.65 780.27,387.6 780.11,387.53 780.02,387.5 
	780,387.49 779.97,387.5 748.7,399.78 735.31,432.28 "
        />
        <polyline class="st0" points="843.2,439.92 843.18,439.91 824.86,432.21 " />
        <polyline
          class="st0"
          points="824.85,432.2 793.33,418.95 780.27,387.6 780.2,387.43 780.18,387.38 780.18,387.37 780.17,387.36 
	780.18,387.35 780.18,387.34 792.45,356.06 824.9,342.69 843.18,335.16 843.2,335.15 "
        />
        <polyline
          class="st0"
          points="735.27,342.62 767.9,356.06 780.18,387.35 780.18,387.36 780.18,387.37 780.14,387.45 780.11,387.53 
	767.02,418.95 735.31,432.28 "
        />
        <polygon
          class="st0"
          points="843.15,387.49 824.86,432.2 824.85,432.2 780.24,450.41 735.31,432.28 717.32,387.51 735.27,342.62 
	735.27,342.61 780.22,324.59 824.9,342.69 "
        />
        <polygon
          class="st0"
          points="811.59,400.65 824.86,432.2 824.85,432.2 793.33,418.95 780.24,450.41 767.02,418.95 735.31,432.28 
	748.7,399.78 717.32,387.51 748.7,375.22 735.27,342.62 735.27,342.61 767.9,356.06 780.22,324.59 792.45,356.06 824.9,342.69 
	811.59,374.34 843.15,387.49 "
        />
        <polyline class="st0" points="824.9,468.63 811.59,500.29 780.19,513.37 780.15,513.39 " />
        <line class="st0" x1="832.56" y1="450.41" x2="832.53" y2="450.46" />
        <line class="st0" x1="727.81" y1="450.47" x2="727.79" y2="450.41" />
        <line class="st0" x1="780" y1="513.45" x2="780" y2="513.44" />
        <polyline class="st0" points="780.18,513.29 792.45,482 824.9,468.63 " />
        <line class="st0" x1="843.2" y1="461.09" x2="843.18" y2="461.1" />
        <polyline class="st0" points="780.27,513.55 780.19,513.37 780.18,513.33 " />
        <line class="st0" x1="780.15" y1="513.39" x2="780.11" y2="513.48" />
        <line class="st0" x1="780.18" y1="513.32" x2="780.18" y2="513.31" />
        <path
          class="st0"
          d="M780.22,450.54L767.9,482l-20.11-8.28l-0.01-0.01c-1.92-2.75-4.05-5.31-6.4-7.6L780.22,450.54z"
        />
        <path
          class="st0"
          d="M824.86,558.15l-10.78,4.4c-3.47-4.53-7.41-9.07-11.26-13.66L824.86,558.15z"
        />
        <path
          class="st0"
          d="M843.15,513.44L831.88,541h-0.01c-0.37,0.16-0.72,0.36-1.09,0.59c-6.19,3.82-5.44,7.53-3.82,11.43l-2.1,5.13
	l-13.27-31.55L843.15,513.44z"
        />
        <polygon
          class="st0"
          points="843.15,513.44 811.59,526.6 780.27,513.55 780.11,513.48 780.01,513.45 780.15,513.39 780.18,513.33 
	780.18,513.32 780.17,513.31 780.18,513.31 780.18,513.29 767.9,482 780.22,450.54 824.9,468.63 "
        />
        <path
          class="st0"
          d="M780.18,513.31h-0.01l0.01,0.01v0.01l-0.03,0.06l-0.14,0.06h-0.02l-12.23-4.8
	c-6.36-8.48-10.27-17.56-15.83-28.11c-1.25-2.37-2.63-4.66-4.14-6.82L767.9,482l12.28,31.29V513.31z"
        />
        <path
          class="st0"
          d="M779.99,513.45l-6.07,2.38c-2.32-2.36-4.34-4.74-6.16-7.18L779.99,513.45z"
        />
        <path
          class="st0"
          d="M780.11,513.48l-2.47,5.92c-0.47-0.46-0.94-0.9-1.41-1.34c-0.8-0.74-1.57-1.49-2.31-2.23l6.07-2.38h0.02
	L780.11,513.48z"
        />
        <path
          class="st0"
          d="M786.8,529.22c-2.94-3.44-6-6.77-9.16-9.82l2.47-5.92l0.16,0.07L786.8,529.22z"
        />
        <path
          class="st0"
          d="M824.86,558.15l-22.04-9.26l-0.02-0.01c-1.57-1.88-3.13-3.76-4.64-5.67c-3.6-4.53-7.38-9.35-11.36-13.99
	l-6.53-15.67l31.32,13.05L824.86,558.15z"
        />
        <polygon
          class="st0"
          points="843.15,513.44 811.59,526.6 780.27,513.55 780.11,513.48 780.01,513.45 780.15,513.39 780.18,513.33 
	780.18,513.32 780.17,513.31 780.18,513.31 780.18,513.29 767.9,482 780.22,450.54 792.45,482 824.9,468.63 811.59,500.29 "
        />
        <polyline
          class="st0"
          points="958.56,324.47 937.59,374.34 906,387.51 874.7,375.22 853.79,324.47 "
        />
        <polyline
          class="st0"
          points="958.56,450.53 937.59,400.65 906,387.49 874.7,399.78 853.79,450.53 "
        />
        <polyline
          class="st0"
          points="969.2,335.15 918.45,356.06 906.17,387.36 919.33,418.95 969.2,439.92 "
        />
        <polyline
          class="st0"
          points="843.15,439.92 893.02,418.95 906.18,387.36 893.9,356.06 843.15,335.15 "
        />
        <polygon
          class="st0"
          points="969.14,387.49 950.9,342.69 906.22,324.59 861.26,342.61 843.32,387.51 861.31,432.28 906.24,450.41 
	950.85,432.2 "
        />
        <polygon
          class="st0"
          points="906.22,324.59 893.9,356.06 861.26,342.61 874.7,375.22 843.32,387.51 874.7,399.78 861.31,432.28 
	893.02,418.95 906.24,450.41 919.33,418.95 950.85,432.2 937.59,400.65 969.14,387.49 937.59,374.34 950.9,342.69 918.45,356.06 "
        />
        <polyline class="st0" points="853.79,450.41 853.81,450.47 861.26,468.55 " />
        <polyline class="st0" points="906.01,513.45 906.15,513.39 906.19,513.37 " />
        <polyline class="st0" points="958.56,450.41 958.53,450.46 958.53,450.47 950.9,468.63 " />
        <polyline class="st0" points="905.99,513.45 874.7,501.17 861.27,468.56 861.26,468.56 " />
        <line class="st0" x1="906" y1="513.45" x2="906" y2="513.44" />
        <polyline
          class="st0"
          points="950.9,468.63 918.45,482 906.18,513.3 906.18,513.31 906.17,513.31 906.18,513.32 906.19,513.37 "
        />
        <polyline
          class="st0"
          points="861.26,468.56 861.27,468.56 893.9,482 906.18,513.3 906.18,513.31 906.18,513.32 906.15,513.39 
	906.11,513.48 "
        />
        <line class="st0" x1="843.18" y1="461.1" x2="843.15" y2="461.09" />
        <path
          class="st0"
          d="M874.7,525.72l-3.15,7.65c-3.49-0.88-6.95-1.42-10.47-0.32c-2.93,0.91-5.26,3.35-7.72,5.39l-10.04-24.98
	L874.7,525.72z"
        />
        <path
          class="st0"
          d="M963.96,515.6c-2.41,3.96-4.53,8.07-6.33,12.23c-3.04,7.08-3.87,13.54-13.14,14.57
	c-0.08,0.01-0.16,0.02-0.24,0.03h-0.01l-6.65-15.83L963.96,515.6z"
        />
        <path
          class="st0"
          d="M967.71,509.94c-0.53,0.75-1.05,1.5-1.56,2.26l-28.56-11.91l13.31-31.66L967.71,509.94z"
        />
        <polygon
          class="st0"
          points="937.59,500.29 906.19,513.37 906.27,513.55 906.11,513.48 906.01,513.45 905.99,513.45 874.7,525.72 
	843.32,513.46 861.26,468.56 861.27,468.56 906.22,450.54 950.9,468.63 "
        />
        <path
          class="st0"
          d="M906.11,513.48l-9.63,23.12c-4.4-0.13-8.51-0.75-13.1-0.79c-4.05-0.05-7.95-1.46-11.83-2.44l3.15-7.65
	l31.29-12.27h0.02L906.11,513.48z"
        />
        <path
          class="st0"
          d="M914.95,534.38c-5.31,0.78-10.74,2.22-16.83,2.25c-0.55,0-1.1-0.01-1.64-0.03l9.63-23.12l0.16,0.07
	L914.95,534.38z"
        />
        <path
          class="st0"
          d="M944.24,542.43c-6.64,0.7-4.73-1.09-9.62-4.35c-6.99-4.66-13.24-4.67-19.67-3.7l-8.68-20.83l31.32,13.05
	L944.24,542.43z"
        />
        <path
          class="st0"
          d="M966.15,512.2c-0.75,1.11-1.48,2.24-2.18,3.39l-0.01,0.01l-26.37,11l-31.32-13.05l-0.08-0.18l31.4-13.08
	L966.15,512.2z"
        />
        <polygon
          class="st0"
          points="937.59,500.29 906.19,513.37 906.27,513.55 906.11,513.48 906.01,513.45 905.99,513.45 874.7,525.72 
	843.32,513.46 874.7,501.17 861.26,468.56 861.27,468.56 893.9,482 906.22,450.54 918.45,482 950.9,468.63 "
        />
        <line class="st0" x1="987.36" y1="306.23" x2="969.2" y2="313.86" />
        <path
          class="st0"
          d="M1066.6,310.36l-34.3,14l13.09-31.46l10.88,4.57c0,0.02,0.01,0.04,0.02,0.06c0.17,0.43,0.4,0.88,0.71,1.37
	C1059.7,302.99,1063.04,306.77,1066.6,310.36z"
        />
        <polygon class="st0" points="987.36,306.22 969.38,261.46 1000.75,273.72 " />
        <polygon class="st0" points="987.32,216.56 1000.75,249.17 969.38,261.46 " />
        <path
          class="st0"
          d="M1002.82,222.94l-15.49-6.38l1.97-0.79C994.3,218.73,999.03,218.91,1002.82,222.94z"
        />
        <polygon class="st0" points="1032.3,324.36 987.36,306.23 1019.07,292.9 " />
        <path
          class="st0"
          d="M1056.27,297.47l-10.88-4.57l-3.03-7.28c1.23,0.11,2.42,0.43,3.49,1.19c0.4,0.28,0.77,0.63,1.14,1.05
	c2.84,3.3,0.48,4.12,5.46,3.58C1056.06,292.82,1055.22,294.85,1056.27,297.47z"
        />
        <path
          class="st0"
          d="M1045.39,292.9l-13.09,31.46l-13.23-31.46l6.93-16.61c0.67,0.8,1.3,1.69,1.95,2.59
	c1.74,2.5,3.53,5.12,6.29,6.17c2.58,0.99,5.46,0.33,8.11,0.57h0.01L1045.39,292.9z"
        />
        <path
          class="st0"
          d="M1026,276.29l-6.93,16.61l-31.71,13.33v-0.01l13.39-32.5l15.46-6.06c0.02,0.01,0.03,0.03,0.04,0.04
	c2.76,2.93,6.26,4.95,9.08,7.82C1025.56,275.76,1025.78,276.02,1026,276.29z"
        />
        <path
          class="st0"
          d="M1016.21,267.66l-15.46,6.06l-31.37-12.26l31.37-12.29l6.97,2.73C1009.33,256.9,1012.77,263.95,1016.21,267.66z
	"
        />
        <path
          class="st0"
          d="M1007.72,251.9l-6.97-2.73l-13.43-32.61h0.01l15.49,6.38l0.01,0.01c0.54,0.57,1.05,1.22,1.55,1.97
	c5.49,8.31,0.31,17.33,3.19,26.54C1007.61,251.6,1007.66,251.75,1007.72,251.9z"
        />
        <polyline
          class="st0"
          points="1076.95,342.69 1063.65,374.34 1032.25,387.43 1032.2,387.45 1032.08,387.5 1032.06,387.51 
	1032.03,387.5 1000.75,375.22 987.32,342.62 987.32,342.61 "
        />
        <line class="st0" x1="979.86" y1="450.48" x2="979.84" y2="450.53" />
        <polyline
          class="st0"
          points="987.36,432.28 1000.75,399.78 1032.03,387.5 1032.06,387.49 1032.08,387.5 1032.17,387.53 
	1032.32,387.6 1063.65,400.65 1076.91,432.2 "
        />
        <line class="st0" x1="1084.61" y1="450.53" x2="1084.58" y2="450.47" />
        <line class="st0" x1="1095.26" y1="439.92" x2="1095.23" y2="439.91" />
        <line class="st0" x1="1095.26" y1="335.15" x2="1095.23" y2="335.16" />
        <polyline
          class="st0"
          points="1076.95,342.69 1044.51,356.06 1032.23,387.33 1032.22,387.36 1032.23,387.38 1032.25,387.43 
	1032.32,387.6 1045.39,418.95 1076.91,432.2 "
        />
        <polyline
          class="st0"
          points="969.2,439.92 1019.07,418.95 1032.24,387.36 1019.95,356.06 969.2,335.15 "
        />
        <polygon
          class="st0"
          points="1095.2,387.49 1076.91,432.2 1032.3,450.41 987.36,432.28 969.38,387.51 987.32,342.62 987.32,342.61 
	1032.28,324.59 1076.95,342.69 "
        />
        <polygon
          class="st0"
          points="1032.28,324.59 1019.95,356.06 987.32,342.61 1000.75,375.22 969.38,387.51 1000.75,399.78 
	987.36,432.28 1019.07,418.95 1032.3,450.41 1045.39,418.95 1076.91,432.2 1063.65,400.65 1095.2,387.49 1063.65,374.34 
	1076.95,342.69 1044.51,356.06 "
        />
        <line class="st0" x1="1084.61" y1="450.41" x2="1084.58" y2="450.46" />
        <line class="st0" x1="979.86" y1="450.47" x2="979.84" y2="450.41" />
        <path
          class="st0"
          d="M994.76,486.62c-7.99,3.67-14.99,9.19-20.95,15.76l13.51-33.82L994.76,486.62z"
        />
        <path
          class="st0"
          d="M1032.28,450.54l-10,25.52c-2.98,1.06-5.95,2.14-8.9,3.23l-26.05-10.73L1032.28,450.54z"
        />
        <path
          class="st0"
          d="M1061.49,462.37c-7.21,2.56-14.45,5.05-21.68,7.55l-7.53-19.38L1061.49,462.37z"
        />
        <path
          class="st0"
          d="M1013.38,479.29c-5.47,2.02-10.9,4.11-16.26,6.29c-0.8,0.32-1.59,0.67-2.36,1.04l-7.44-18.06h0.01
	L1013.38,479.29z"
        />
        <path
          class="st0"
          d="M1039.81,469.92c-5.86,2.02-11.71,4.05-17.53,6.14l10-25.52L1039.81,469.92z"
        />
        <path
          class="st0"
          d="M1144.73,330.02l-31.41,12.59l-5.41-13.12c1.1,0.02,2.22,0.06,3.37,0.11c8.65,0.4,17.61,0.28,26.34,0.71
	C1139.85,330.42,1142.25,330.26,1144.73,330.02z"
        />
        <polyline
          class="st0"
          points="1158.25,387.43 1158.19,387.45 1158.07,387.5 1158.05,387.51 1158.02,387.5 1126.75,375.22 
	1113.32,342.62 1113.32,342.61 "
        />
        <polyline
          class="st0"
          points="1158.16,387.53 1158.07,387.5 1158.05,387.49 1158.02,387.5 1126.75,399.78 1113.36,432.28 "
        />
        <polyline class="st0" points="1158.23,387.37 1158.22,387.36 1158.23,387.36 " />
        <polyline class="st0" points="1158.16,387.53 1158.19,387.45 1158.23,387.37 " />
        <polyline class="st0" points="1145.95,356.06 1113.32,342.62 1113.32,342.61 " />
        <line class="st0" x1="1095.23" y1="335.16" x2="1095.2" y2="335.15" />
        <line class="st0" x1="1095.23" y1="439.91" x2="1095.2" y2="439.92" />
        <path
          class="st0"
          d="M1146.41,422.14c-8.61,3.83-15.39,9.39-23.6,13.95l-9.45-3.81l31.71-13.33L1146.41,422.14z"
        />
        <path
          class="st0"
          d="M1176.06,352.7l-0.18,1.14l-5.38,2.22l-10.42-26.85c0.39,0.06,0.77,0.12,1.15,0.19c0.03,0,0.03,0,0.06,0
	C1172.36,331.53,1177.59,342.77,1176.06,352.7z"
        />
        <path
          class="st0"
          d="M1156.54,329.02l-10.59,27.04l-32.63-13.45l31.41-12.59h0.01C1148.69,329.63,1152.79,329.03,1156.54,329.02z"
        />
        <polygon
          class="st0"
          points="1158.32,387.6 1158.16,387.53 1145.07,418.95 1113.36,432.28 1095.38,387.51 1113.32,342.62 
	1113.32,342.61 1145.95,356.06 1158.23,387.35 1158.23,387.37 1158.25,387.43 "
        />
        <path
          class="st0"
          d="M1168.48,411.98c-4.68,5.6-13.24,6.61-19.87,9.22c-0.75,0.3-1.48,0.61-2.2,0.94l-1.34-3.19l13.09-31.42
	l0.16,0.07L1168.48,411.98z"
        />
        <path
          class="st0"
          d="M1170.54,408.6c-0.55,1.29-1.25,2.4-2.06,3.38l-10.16-24.38l13.97,5.82
	C1172.46,398.78,1172.38,404.26,1170.54,408.6z"
        />
        <path
          class="st0"
          d="M1172.29,393.42l-13.97-5.82l-0.07-0.17l13.9-5.8c-0.03,0.48-0.05,0.96-0.07,1.45
	C1171.96,386.22,1172.18,389.8,1172.29,393.42z"
        />
        <path
          class="st0"
          d="M1175.88,353.84c-1.48,9.39-3.19,18.17-3.73,27.79l-13.9,5.8l-0.02-0.06v-0.02l12.27-31.29L1175.88,353.84z"
        />
        <path
          class="st0"
          d="M1170.5,356.06l-12.27,31.29l-12.28-31.29l10.59-27.04c1.22-0.01,2.4,0.05,3.53,0.19h0.01L1170.5,356.06z"
        />
        <polygon
          class="st0"
          points="1158.32,387.6 1158.16,387.53 1145.07,418.95 1113.36,432.28 1126.75,399.78 1095.38,387.51 
	1126.75,375.22 1113.32,342.62 1113.32,342.61 1145.95,356.06 1158.23,387.35 1158.23,387.37 1158.25,387.43 "
        />{" "}
      </svg>
    </div>
  );
}

export default AnimeBackground;
