import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Vision 2030's Financial Landscape",
      description:
        "Analysing the progress made in reshaping finance to align with Vision 2030's objectives.",
      image: <S2 />,
    },
    {
      title: "Fintech & Innovation",
      description:
        "Exploring how technology fosters a more inclusive, sustainable, and resilient financial system.",
      image: <S3 />,
    },
    {
      title: "Data and Analytics",
      description:
        "Unveiling the power of data, emerging trends, and blockchain's impact on trust and analytics.",
      image: <S8 />,
    },
    {
      title: "Payment Regulatory Framework",
      description:
        "Examining the role of governments in promoting inclusive finance through regulation.",
      image: <S1 />,
    },
  ];
  const content1 = [
    {
      title: "Insights",
      description:
        "The future's evolution stems from current needs and collective aspirations, especially in digital banking and financial services. Visionary leaders will illuminate the way forward at the upcoming conference. Expect dynamic panels, fireside chats, discussions, and demos covering diverse topics.",
    },
    {
      title: "Networking",
      description:
        "Finnovex offers a unique chance to connect with influential leaders, industry veterans, and innovators. Engage and absorb cutting-edge strategies driving this transformation.",
    },
    {
      title: "Spotlight",
      description:
        "Finnovex is the ultimate platform for digital banking leaders to connect with decision-makers effortlessly. Showcase your organisation across multiple channels and engage in personalised networking sessions, premium demos, talks, and fireside chats.",
    },
    {
      title: "Roundtables",
      description:
        "Enhance networking with intimate roundtable sessions guided by leaders, facilitating idea exchange among small groups. Dive into compelling topics, blending networking and idea sharing for an enriched conference experience.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={11}>
                {" "}
                <span className="text-white"></span>
                <h2 className="text-400 text-white">
                  Fulfilling Vision 2030:
                  <br /> Kingdom Banking's Pinnacle - Transforming Finance for a Thriving Saudi
                  Arabia
                </h2>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  Saudi Arabia is pushing for economic diversification, tech advancement, and
                  cultural openness. They aim to host Expo 2030 in Riyadh as a global showcase.
                  Finnovex Saudi Arabia's 24th edition in 2024 aligns with Expo 2030's theme and
                  aims to discuss, innovate, and strategize for Vision 2030's realisation. With the
                  theme "Fulfilling Vision 2030: Kingdom Banking's Pinnacle - Transforming Finance
                  for a Thriving Saudi Arabia," the event will delve into banking's role in Saudi
                  Arabia's transformation.
                  <br />
                  <br />
                  <br />
                  Finnovex Saudi Arabia 2024 aims to transform the financial landscape, gathering
                  200+ attendees to drive Saudi Arabia's banking to new heights, aligning with
                  Vision 2030.
                  <br />
                  <br />
                </h5>
              </Col>
              <Col lg={6} className="mt-5">
                <div className="container-fluid row">
                  {content.map((s, index) => (
                    <Col className="text-center  p-2" lg={6}>
                      <ScrollAnimation
                        animateIn={"fadeInUp"}
                        animateOnce={true}
                        duration={1.5 + index / 8}
                      >
                        <div className="pt-4 px-2 stat-div" style={{ minHeight: 270 }}>
                          {s.image}

                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                            }}
                            className="text-700 mt-4"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </div>
                      </ScrollAnimation>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "36px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  Revolutionising Saudi Arabia's Financial Sector: A Global Outlook
                </h3>
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 mb-5">
                  <Col lg={6} className="align-self-center">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                      }}
                      className="text-400 mt-0"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-0 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6}>
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/images/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "BO2A9941.jpg",
  "DSC_0119.jpg",
  "DSC_0154.jpg",
  "DSC_0168.jpg",
  "DSC_0178.jpg",
  "DSC_0195.jpg",
  "DSC_0332.jpg",
  "DSC_0511.jpg",
  "DSC_9715.jpg",
  "DSC_9854.jpg",
  "DSC_9885.jpg",
];
