import React from "react";

import { Container, Row, Col } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "transparent",
        }}
        className="section pb-2"
      >
        <Container fluid>
          <Container>
            <Row>
              <Col lg={6} className={``}>
                <h4 className=" text-700 text-white">
                  <b>Exibex</b> remains steadfast in its mission to honour visionaries, pioneers,
                  and trailblazers redefining industry norms. The Finnovex Awards celebrate
                  outstanding achievements in the dynamic financial services realm, paying tribute
                  to those fostering revolutionary shifts.
                  <br />
                  <br />
                  More than accolades, these awards symbolise innovation leading to unwavering
                  excellence. They honour exceptional contributions in banking and finance, setting
                  a standard of outstanding performance. These prestigious awards offer a platform
                  for institutions to showcase expertise and identify industry leaders.
                  <br />
                  <br />
                  Beyond recognition, Finnovex Awards inspire other enterprises, propelling them
                  beyond goals. They serve as a benchmark, igniting a passion for achievement and
                  celebrating the pinnacle of success.
                </h4>
                <br />
              </Col>
              <Col lg={6} className={`mb-3 `}>
                <img
                  src={require("assets/images/awards/DSC_0435.JPG")}
                  width="100%"
                  alt="awards"
                  style={{
                    borderRight: "10px solid #c0dea7",
                    borderTop: "10px solid #c0dea7",
                  }}
                />
              </Col>

              {content.map((data, index) => (
                <Col lg={3} key={index} className="stat-div border">
                  <div className="d-flex align-items-center" style={{ minHeight: "125px" }}>
                    <h5 className="text-700 mt-0 text-white">{data}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "Excellence in Mobile Banking ",
  "Excellence in Digital Banking ",
  "Excellence in Fintech ",
  "Excellence in Payments",
  "Excellence in Cyber Security",
  "Excellence in Customer Experience Enhancement",
  "Excellence in InsurTech",
  "Excellence in Open Banking",
  "Excellence in Cloud Banking",
  "Outstanding Contribution to Financial Inclusion Initiative",
  "Outstanding Contribution to Strategic National Development Initiatives",
  "Outstanding Contribution to Fintech Initiatives",
  "Finnovator of the Year, Saudi Arabia ",
  "Finfluencer of the Year, Saudi Arabia ",
  "Financial Women Trailblazer of the Year ",
  "Disruptive Techblazer of the Year ",
  "InsurTech Personality of the Year ",
  "CEO of the Year",
  "COO of the Year",
  "CDO of the Year",
  "CIO of the Year",
  "CTO of the Year",
  "CFO of the Year",
  "CISO of the Year",
];

const content2 = [];
