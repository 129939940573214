import React from "react";
import { Container, Button, Row, Col } from "reactstrap";

function WhyAttend() {
  // const [isOpen, setIsOpen] = React.useState(false);

  // const toggle = i => setIsOpen(i);
  console.log(waContent);
  return (
    <div
      style={{
        backgroundColor: "transparent",
      }}
      className="section p-5"
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="text-700 mb-4 text-white text-uppercase">Why Attend</h2>
            <h5 className="text-white text-400">
              Join us for an unparalleled opportunity to network and connect with over 150+ senior
              leaders from diverse industries. Our carefully curated agenda is designed to help you
              tackle your current business-critical challenges and drive your industry forward with
              innovative ideas and strategies. By attending our summit, you will gain access to
              valuable resources that are guaranteed to help you:
              <br />
              <br />
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {waContent.map((data, index) => (
            <Col lg={6} key={index} className="stat-div border">
              <div className=" p-3" style={{ minHeight: "130px" }}>
                <h4 className="text-700 mt-0 text-white">{data.title}</h4>
                <br />
                <h5 className="text-400 mt-0 text-white">{data.description}</h5>
              </div>
            </Col>
          ))}
        </Row>
        <br />
        <br />
        <Row className="justify-content-center">
          <Button className="p-2 rounded-0" color="primary" size="lg" href="/register">
            <h5 className="m-0 text-400 text-white">Register Now</h5>
          </Button>
        </Row>
      </Container>
    </div>
  );
}
export default WhyAttend;

const waContent = [
  {
    title: "Forge Connections",
    description: "Connect with senior leaders to expand your network and unlock new opportunities.",
  },
  {
    title: "Build Partnerships",
    description: "Cultivate strong partnerships to accelerate business objectives efficiently.",
  },
  {
    title: "Stay Updated",
    description:
      "Keep abreast of emerging trends and industry developments, staying ahead in your field.",
  },
  {
    title: "Actionable Insights",
    description:
      "Download takeaways to optimise processes, enhance efficiency, and boost profits immediately.",
  },
  {
    title: "De-risk Projects",
    description:
      "Gain insights from industry experts to make informed decisions and mitigate risks.",
  },
  {
    title: "Tech Impact",
    description: "Understand the impact of new technologies for gaining a competitive edge.",
  },
  {
    title: "Networking Opportunities",
    description:
      "Schedule one-on-one meetings to foster meaningful partnerships and accelerate projects.",
  },

  {
    title: "Professional Investment",
    description:
      "Attend for professional development, gaining a competitive edge in your industry.",
  },
];
