import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import S2 from "./svgs/s2";
import S5 from "./svgs/s5";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import S3 from "./svgs/s3";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Vision 2030's Financial Landscape",
      description:
        "Analysing the progress made in reshaping finance to align with Vision 2030's objectives.",
      image: <S2 />,
    },
    {
      title: "Fintech & Innovation",
      description:
        "Exploring how technology fosters a more inclusive, sustainable, and resilient financial system.",
      image: <S3 />,
    },
    {
      title: "Data and Analytics",
      description:
        "Unveiling the power of data, emerging trends, and blockchain's impact on trust and analytics.",
      image: <S8 />,
    },
    {
      title: "Payment Regulatory Framework",
      description:
        "Examining the role of governments in promoting inclusive finance through regulation.",
      image: <S1 />,
    },
  ];
  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" text-700 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2.5rem" : "1.5rem" }}
              >
                <b>
                  Fulfilling Vision 2030:
                  <br /> Kingdom Banking's Pinnacle - Transforming Finance for a Thriving Saudi
                  Arabia
                </b>
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                Saudi Arabia is pushing for economic diversification, tech advancement, and cultural
                openness. They aim to host Expo 2030 in Riyadh as a global showcase. Finnovex Saudi
                Arabia's 24th edition in 2024 aligns with Expo 2030's theme and aims to discuss,
                innovate, and strategize for Vision 2030's realisation. With the theme "Fulfilling
                Vision 2030: Kingdom Banking's Pinnacle - Transforming Finance for a Thriving Saudi
                Arabia," the event will delve into banking's role in Saudi Arabia's transformation.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#83c555" }}>
                  Transforming the Financial Inclusion Together{" "}
                </b>
                <br />
                <br />
                Finnovex Saudi Arabia 2024 aims to transform the financial landscape, gathering 200+
                attendees to drive Saudi Arabia's banking to new heights, aligning with Vision 2030.
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              <div className="container-fluid row pr-0">
                {content.map((s, index) => (
                  <Col className="text-center  p-2 px-0" lg={6} xs={6}>
                    <ScrollAnimation
                      animateIn={"fadeInUp"}
                      animateOnce={true}
                      duration={0.5 + index / 8}
                    >
                      <div className="pt-4 px-2 stat-div" style={{ minHeight: 300 }}>
                        {s.image}

                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-4"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </div>
                    </ScrollAnimation>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
