import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import PastSpeakers from "components/PastSpeakers";

import ScrollUpButton from "react-scroll-up-button";

function Speakers() {
  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <ProfilePageHeader title="PAST SPEAKERS" />
      <PastSpeakers showAll={true} />
      <Footer />
    </div>
  );
}

export default Speakers;
