import React from "react";
import Slider from "react-slick";
import { Container, Row, Col, Card, Button } from "reactstrap";

function SponsorOld({ sponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 2,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      style={{
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 5,
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <h1 className="text-400 text-i text-center mb-4">
              <b>PAST SPONSOR AND PARTNERS</b>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className={"mx-auto"}>
            <Slider {...settings}>
              {sponsors.map((data, i) => (
                <a target="_blank" rel="noopener noreferrer" href={data.link} key={i}>
                  <div
                    style={{
                      background: "white",
                      display: "flex",
                      flexDirection: "column",
                      aspectRatio: "3/2",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={require(`assets/images/sponsors/${data.image}`)}
                      className="mx-auto"
                      alt={data.name}
                      width="100%"
                      style={{ maxWidth: "400px", borderRadius: 0 }}
                    />
                  </div>
                </a>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SponsorOld;
